export default {
  api: {
    operationSucceed: 'ดำเนินงานสำเร็จ',
    operationFailed: 'ดำเนินการล้มเหลว',
    errorTip: 'ข้อความผิดพลาด',
    errorMessage: 'การดำเนินการล้มเหลว ระบบผิดปกติ!',
    timeoutMessage: 'หมดเวลาเข้าสู่ระบบ โปรดเข้าสู่ระบบอีกครั้ง!',
    apiTimeoutMessage: 'คำขออินเทอร์เฟซหมดเวลา โปรดรีเฟรชแล้วลองอีกครั้ง!',
    apiRequestFailed: 'คำขอผิดพลาด โปรดลองอีกครั้งในภายหลัง',
    networkException: 'เครือข่ายผิดปกติ',
    networkExceptionMsg:
      'เครือข่ายมีความผิดปกติ โปรดตรวจสอบว่าการเชื่อมต่อเครือข่ายของคุณเป็นปกติหรือไม่!',

    errMsg401: 'ผู้ใช้ไม่ได้รับอนุญาต (โทเค็น, ชื่อผู้ใช้, รหัสผ่านไม่ถูกต้อง)!',
    errMsg403: 'ผู้ใช้ได้รับอนุญาต แต่ห้ามการเข้าถึง !',
    errMsg404: 'ข้อผิดพลาดในการร้องขอเครือข่าย ไม่พบทรัพยากร!',
    errMsg405: 'ข้อผิดพลาดในการร้องขอเครือข่าย ไม่อนุญาตให้ใช้วิธีการร้องขอ!',
    errMsg408: 'คำขอเครือข่ายหมดเวลา!',
    errMsg500: 'เซิร์ฟเวอร์ผิดพลาด โปรดติดต่อผู้ดูแลระบบ!',
    errMsg501: 'เครือข่ายไม่ได้ดำเนินการ!',
    errMsg502: 'เครือข่ายผิดพลาด!',
    errMsg503:
      'เซิร์ฟเวอร์ไม่พร้อมใช้งาน เซิร์ฟเวอร์โอเวอร์โหลดชั่วคราวหรืออยู่ระหว่างการปรับปรุง!',
    errMsg504: 'หมดเวลาการเชื่อมต่อกับเครือข่าย!',
    errMsg505: 'เวอร์ชัน HTTP ไม่รองรับคำขอนี้!',
  },
  exception: {
    backLogin: 'กลับสู่การเข้าสู่ระบบ',
    backHome: 'กลับไปที่หน้าแรก',
    subTitle403: 'ขออภัย คุณไม่ได้รับอนุญาตให้เข้าถึงหน้านี้',
    subTitle404: 'ขออภัย หน้าที่คุณเข้าชมไม่มีอยู่จริง',
    subTitle500: 'ขออภัย เซิร์ฟเวอร์รายงานข้อผิดพลาด',
    noDataTitle: 'หน้าปัจจุบันไม่มีข้อมูล',
    networkErrorTitle: 'เครือข่ายผิดพลาด',
    networkErrorSubTitle: 'ขออภัย การเชื่อมต่อเครือข่ายของคุณขัดข้อง โปรดตรวจสอบเครือข่ายของคุณ!',
  },
  app: {
    title: 'หน้าแรก',
    description: 'เอซมอลล์, เติมเงินเกม, เติมเงิน Dmen, เติมเงินโทเค็น',
    tip: 'แจ้งเตือน',
    copyright: 'Ace Playing',
    icp: '京ICP備20009004號',
    address: 'ที่อยู่บริษัท: No. 28, Beisanhuan East Road, Chaoyang District, Beijing',
    account: 'บัญชีของฉัน',
    changePwd: 'เปลี่ยนรหัสผ่าน',
    chargeRecord: 'บันทึกการเติมเงิน',
    helpCenter: 'ศูนย์ช่วยเหลือ',
    chargeNow: 'เติมเงินทันที',
  },
  login: {
    email: 'อีเมล',
    verifyCode: 'รหัสยืนยัน',
    password: 'รหัสผ่าน',
    passwordHolder: 'รหัสผ่าน (โปรดป้อนตัวเลขและตัวอักษรรวมกัน 8 ถึง 30 ตัว)',
    loginButton: 'เข้าสู่ระบบ',
    freeAccount: 'ลงทะเบียนฟรี',
    freeAccountCreate: 'สร้างบัญชีฟรี',
    forgetPassword: 'ลืมรหัสผ่าน?',
    findPassword: 'กู้คืนรหัสผ่าน',
    findEmailSent:
      'อีเมลกู้คืนรหัสผ่านถูกส่งไปยังอีเมลของคุณแล้ว โปรดคลิกลิงก์ในอีเมลเพื่อรีเซ็ตรหัสผ่านของคุณ',
    forgetFormTitle: 'รีเซ็ตรหัสผ่าน',
    oldPassword: 'รหัสผ่านเก่า',
    newPassword: 'รหัสผ่านใหม่',
    repeatPassword: 'กรุณาป้อนรหัสผ่านของคุณอีกครั้ง',
    policyCheck: 'เลือกตกลง',
    policy: '"นโยบายความเป็นส่วนตัวของ Ace Interactive Entertainment"',
    hadAccount: 'มีบัญชีอยู่แล้ว?',
    backSignIn: 'กลับสู่การเข้าสู่ระบบ',

    logoutButton: 'ออกจากระบบ',
    registerButton: 'ลงทะเบียน',

    otherSignIn: 'วิธีเข้าสู่ระบบอื่น ๆ ',

    // notify
    loginSucceed: 'เข้าสู่ระบบสำเร็จ',
    logoutSucceed: 'คุณได้ออกจากระบบบัญชีปัจจุบันของคุณแล้ว',
    registerSucceed: 'ลงทะเบียนสำเร็จ',
    changePwdSucceed: 'แก้ไขรหัสผ่านสำเร็จ',
    resetPwdSucceed: 'รีเซ็ตรหัสผ่านสำเร็จ',

    // ValidateMsg
    emailValidateMsg: 'กรุณาใส่ที่อยู่อีเมลที่ถูกต้อง',
    passwordValidateMsg: 'โปรดป้อนตัวเลขและตัวอักษรรวมกันตั้งแต่ 8 ถึง 30 หลัก',
    verifySentMsg: 'ส่งรหัสยืนยันแล้ว',
    verifyValidateMsg: 'โปรดป้อนรหัสยืนยัน 6 หลัก',
    policyValidateMsg: 'ทำการเลือกก่อนถึงจะสามารถลงทะเบียนได้',
    diffPwdValidateMsg: 'รหัสผ่านที่ป้อนสองครั้งไม่สอดคล้องกัน',
  },
  help: {
    helpModalTitle: 'ศูนย์ช่วยเหลือ',
    helpTipsTitle: 'คำเตือน',
    helpTips_1:
      'ไอเทมในเกมที่ได้รับจากการเติมเงินแบบใหม่บนแพลตฟอร์มนี้ใช้ได้กับ "D-MEN: The Defenders" เท่านั้น ',
    helpTips_2:
      'เมื่อคุณทำการสั่งซื้อ โปรดอ่านอย่างละเอียดและยืนยันชื่อ, ราคา, สกุลเงิน, จำนวน, ข้อมูลจำเพาะ, รายละเอียดสินค้า, ขั้นตอนการสั่งซื้อ, ข้อมูลการจัดส่ง, ข้อมูลติดต่อ, วิธีการชำระเงิน และข้อมูลอื่น ๆ ของสินค้าที่ซื้อ เพื่อหลีกเลี่ยงการสูญเสียส่วนบุคคล โปรดอย่าเปิดเผยข้อมูลส่วนบุคคลแก่ผู้อื่น',
    serviceTitle: 'รายละเอียดการบริการ',
    service_1:
      'บริการนี้ใช้ได้เฉพาะกับผู้ใช้ที่ถูกผูกไว้กับแอป "D-MEN: The Defenders"เท่านั้น ผู้ใช้ทีไม่ได้ทำการผูกบัญชีจำเป็นต้องดำเนินการผูกบัญชีในเกมให้เสร็จสิ้นเพื่อใช้บริการนี้',
    service_2:
      'สำคัญ: หลังจากป้อนหมายเลขบัญชีแล้ว โปรดตรวจสอบข้อมูลบัญชีที่แสดงอย่างระมัดระวัง จากนั้นดำเนินการในขั้นตอนต่อไปหลังจากยืนยันว่าถูกต้อง! (หากมีการชำระเงินผิดบัญชี โปรดเข้าใจว่าเจ้าหน้าที่จะไม่สามารถคืนเงินได้!)',
    service_3:
      'หลังจากชำระเงินสำเร็จ ระบบจะส่งไอเทมในเกมที่เกี่ยวข้องไปยังบัญชีที่มีมูลค่าที่เก็บไว้ของคุณ โปรดตรวจสอบในเกม หากคุณไม่ได้รับไอเทมในเกมที่ซื้อหลังจากโหลดซ้ำสำเร็จ 30 นาที โปรดติดต่อฝ่ายบริการลูกค้าโดยใช้วิธีการต่อไปนี้ เราจะแก้ไขปัญหาให้คุณอย่างทันท่วงที',
    contactTitle: 'รายการช่องทางบริการลูกค้า',
    contactEmail: 'อีเมลบริการลูกค้า',
    contactFacebook: 'ลิงก์เฟสบุ๊ก',
    contactDiscord: 'ลิงก์ดิสคอส',
  },
  charge: {
    title: 'รายการสินค้า',
    description: 'เอซมอลล์, เติมเงินเกม, เติมเงิน Dmen, เติมเงินโทเค็น',
    coinSelect: 'เลือกสกุลเงิน',
    merchandiseList: 'รายการสินค้าเกม',
    buyNow: 'ซื้อทันที',
    quickLimit: 'แฟลชเซลล์จำกัดเวลา',
    buyLimit: 'จำกัด {limit} ชิ้นต่อตัวละคร',
    chooseRole: 'เลือกตัวละคร',
    server: 'เซิร์ฟเวอร์',
    serverIdHolder: 'กรุณาป้อน ID เซิร์ฟเวอร์',
    roleID: 'ID ตัวละคร',
    roleIDHolder: 'โปรดป้อน ID ตัวละคร',
    searchResult: 'ผลการค้นหา',
    addRole: 'เพิ่มฮีโร่',
    roleInfo: 'ข้อมูลตัวละคร',
    roleLevel: 'เลเวล {level}',
    gameName: 'ชื่อเกม',
    action: 'ดำเนินงาน',
    confirmDel: 'ยืนยันการลบหรือไม่',
    paymentWay: 'วิธีการชำระเงิน',
    paymentAmount: 'จำนวนเงินที่ชำระ',
    roleLimit: 'จำนวนเงินที่เหลือที่ซื้อโดยตัวละครนี้: ',
    payNow: 'ชำระเงินทันที',
    payJumpTips: 'กำลังไปที่แพลตฟอร์มการชำระเงินของบุคคลที่สาม โปรดรอสักครู่...',
    paid: 'ชำระเงินเรียบร้อยแล้ว',
  },
  orders: {
    title: 'รายการสั่งซื้อ',
    description: 'รายการสั่งซื้อ',
    ordersTitle: 'รายการสั่งซื้อ',
    searchDate: 'วันที่สอบถาม',
    selectGame: 'เลือกเกม',
    allGame: 'เกมทั้งหมด',
    orderNo: 'หมายเลขคำสั่งซื้อ',
    gameName: 'ชื่อเกม',
    merchandiseName: 'ชื่อสินค้า',
    merchandiseDetail: 'ข้อมูลสินค้า',
    orderAmount: 'จำนวนเงินที่สั่งซื้อ',
    createAt: 'เวลาสร้าง',
    orderStatus: 'สถานะการสั่งซื้อ',
    statusGoing: 'การชำระเงิน',
    statusSucceed: 'ชำระเงินสำเร็จ',
    statusUnknown: 'โปรดไปที่บันทึกการเติมเงินเพื่อดูผลการชำระเงิน',
    statusFailed: 'ชำระเงินล้มเหลว',
    action: 'ดำเนินงาน',
    viewDetail: 'ดูรายละเอียด',
    detailTitle: 'รายละเอียดการสั่งซื้อ',
    backToOrders: 'รายการสั่งซื้อคืน',
    orderInfo: 'ข้อมูลการสั่งซื้อ',
    merchandiseInfo: 'ข้อมูลสินค้า',
    price: 'ราคาต่อหน่วย',
    quantity: 'จำนวน',
    discount: 'โปรโมชั่น',
    payResult: 'ผลการชำระเงิน',
    viewOrder: 'ตรวจสอบคำสั่งซื้อ',
  },
  order: {
    title: 'รายละเอียดการสั่งซื้อ',
    description: 'รายละเอียดการสั่งซื้อ',
  },
  notFound: {
    title: '404',
    description: '404',
  },
  result: {
    title: 'โปรโมชั่น',
    description: 'โปรโมชั่น',
  },
};
